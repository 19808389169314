
.newKeFu-wrapper {
  height: 100%;
  padding-bottom: 50px;
  box-sizing: border-box;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
      padding: 20px;
    }
  }
  .create-content {
    height: 500px;
  }
  .creating-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    span {
      color: #343434;
      font-size: 24px;
      font-weight: bold;
      margin: 50px 0 50px 10px;
    }
  }
  .dialog-footer {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
  }
  .createStepSucceed-icon {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i {
      font-size: 52px;
      color: #20d28c;
    }
  }
}
:deep .el-button--primary {
  background-color: #1122d8;
  border: #1122d8;
}
