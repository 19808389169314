
    .set-service-data {
        margin-top: 20px;

        .service-data-btn {
            display: flex;
            justify-content: space-between;
        }

        .service-data-table {
            border: 1px solid #eeeeee;
            box-sizing: border-box;
            width: 100%;
            margin-top: 10px;
            display: flex;
            flex-direction: column;

            .data-table-header {
                padding: 0 20px 0 20px;
                display: flex;
                background: #eeeeee;
                height: 60px;
                line-height: 60px;
                box-sizing: border-box;

                span {
                    color: #666666;
                    font-size: 16px;
                }

                .table-header-item {
                    flex: 1;
                    width: 1%;
                    box-sizing: border-box;

                    &:nth-child(2) {
                        span {
                            margin-left: 16px;
                        }
                    }

                    &:nth-child(3),
                    &:nth-child(6),
                    &:last-of-type {
                        text-align: center;
                    }

                    &:nth-child(5) {
                        span {
                            margin-left: 40px;
                        }
                    }
                }
            }

            .data-table-body {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;

                .table-body-item {
                    display: flex;
                    flex-direction: column;

                    .body-item-top,
                    .body-item-bottom {
                        padding: 0 20px 0 20px;
                        box-sizing: border-box;
                        width: 100%;
                        display: flex;
                        border-bottom: 1px solid #eeeeee;

                        .item-top-item,
                        .item-bottom-item {
                            width: 1%;
                            flex: 1;
                            height: 70px;
                            display: flex;
                            align-items: center;

                            &:nth-child(2) {
                                span {
                                    margin-left: 16px;
                                }
                            }

                            i {
                                line-height: 1;
                                cursor: pointer;
                                font-size: 18px;
                                color: #7d7d7d;
                                margin-right: 4px;
                            }

                            span {
                                //flex: 1;
                                font-size: 16px;
                                color: #333;
                            }

                            &:nth-child(3),
                            &:nth-child(6),
                            &:last-of-type {
                                justify-content: center;
                            }

                            &:nth-child(5) {
                                span {
                                    margin-left: 40px;
                                }
                            }
                        }
                    }
                }
            }

            .table-noData {
                height: 500px;
                line-height: 500px;
                text-align: center;
                color: #666666;
                font-size: 16px;
            }
        }
    }

    ::v-deep.el-checkbox {
        .el-checkbox__label {
            display: none;
        }
    }

    ::v-deep .green-dialog2 {
        .el-dialog__header {
            padding: 16px 24px;
            background: #f3f6f8;

            span,
            i {
                font-size: 16px;
                line-height: 1;
                color: #333333;
            }
        }

        .el-dialog__body {
            padding: 22px 24px 22px 0px;
            // 带表格的
            .el-form-item {
                margin-bottom: 24px;
            }

            .el-form-item__label {
                font-size: 16px;
                color: #666666;
                //margin-bottom: 10px;
            }

            .dialog-footer {
                margin-top: 30px;
                text-align: center;
            }
        }
    }

    ::v-deep .el-link + .el-link {
        margin-left: 22px;

        &:before {
            content: "";
            position: absolute;
            left: -12px;
            width: 2px;
            height: 16px;
            background: #dfe9ff;
        }
    }
